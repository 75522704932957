<template>
    <div class="content-wrapper">
        <PageHeader :screenName="form.nomeTecnico" :linkItems="linkItems"/>

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirecionarLista"
        />

        <main class="card p-2 centralizar_responsivo">
            <section class="form">
                <b-form>
                    <b-row>
                        <b-col lg="6" md="6" sm="1font2">
                            <b-form-group label="Nome técnico da doença" 
                            label-for="nome-tecnico-doenca"
                            label-class="label_size_doenca font-weight-bold">
                               <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                                    <div id="nome-tecnico-doenca">
                                        {{form.nomeTecnico}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Vacinas relacionadas" 
                            label-for="vacinas-relacionadas"
                            label-class="label_size_doenca font-weight-bold"
                            v-if="vacinasSelecionadas != ''">
                                <vue-perfect-scrollbar class="altura_max_vacinas_scrollbar" :settings="settings">
                                    <div id="vacinas-relacionadas" class="d-flex flex-wrap centralizar_responsivo">
                                        <div 
                                        v-for="vacinaSelecionada in vacinasSelecionadas" 
                                        :key="vacinaSelecionada.nome_tecnico"
                                        class="rounded vacinas"
                                        :class="vacinaSelecionada.class"
                                        >
                                            {{vacinaSelecionada.nome_tecnico}}
                                        </div>
                                    </div>
                                </vue-perfect-scrollbar>       
                            </b-form-group>
                            <b-form-group label="Vacinas relacionadas" 
                            label-for="vacinas-relacionadas"
                            label-class="label_size_doenca font-weight-bold"
                            v-else>
                                <span id="vacinas-relacionadas">
                                    -
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <b-form-group label="Nome popular da doença" 
                            label-for="nome-popular-doenca"
                            label-class="label_size_doenca font-weight-bold"
                            v-if="form.nomePopular != null">
                                <vue-perfect-scrollbar class="altura_max_input_scrollbar" :settings="settings">
                                    <div id="nome-popular-doenca">
                                        {{form.nomePopular}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                            <b-form-group label="Nome popular da doença"
                            label-for="nome-popular-doenca"
                            label-class="label_size_doenca font-weight-bold"
                            v-else>
                                <span id="nome-popular-doenca">
                                    -
                                </span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" md="6" sm="12">
                            <b-form-group label="Descrição" 
                            label-for="descricao"
                            label-class="label_size_doenca font-weight-bold">
                                <vue-perfect-scrollbar class="altura_max_textarea_scrollbar" :settings="settings">
                                    <div id="descricao">
                                        {{form.descricao}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </section>
            <section v-if="canEdit" class="buttons mt-3">
                <b-button id="save-doenca" 
                    @click.prevent="redirectToEdit()"
                    variant="custom-blue"
                >
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Editar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BButton, BFormTextarea } from 'bootstrap-vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { getIdDoenca } from '@core/utils/store/getStore';
import { setIdDoenca } from '@core/utils/store/setStore';

export default {
    title: 'Detalhes doença',

    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, PageHeader, ErrorModal, VuePerfectScrollbar
    },

    data() {
        return {
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            linkItems: [
                {
                    name: 'Doenças',
                    routeName: 'doenca-list'
                },
                {
                    name: 'Doença',
                    active: true
                }
            ],
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            form: {
                nomeTecnico: '',
                nomePopular: '',
                descricao: '',
            },
            idDoencaShow : null,
            vacinasSelecionadas: [],
            canEdit: false
        }
    },

    mounted() {
        if((getIdDoenca() === null || typeof getIdDoenca() == 'undefined') && typeof this.$route.params.idDoenca == "undefined") {
            return this.openModalError();
        }else if(typeof this.$route.params.idDoenca != "undefined" && this.$route.params.idDoenca !== getIdDoenca()) {
            setIdDoenca(this.$route.params.idDoenca); 
        }

        this.$http.get(this.$api.doencaWithParameter(getIdDoenca())).then(({ data }) => {
            this.preenchimentoFormulario(data);
        });

        this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
    },

    methods: {
        getArrayNomesVacinas(dados) { 
            const arrayVacinas = dados.vacinas;
            for (const element of arrayVacinas) {
                const objeto = 
                    {
                        nome_tecnico: element.nome_tecnico,
                        class: "vacinas"
                    }
                this.vacinasSelecionadas.push(objeto);
            }
        },

        preenchimentoFormulario(data){
            this.idDoencaShow  = data.id_doenca;
            this.form.nomeTecnico = data.nome_tecnico;
            this.form.nomePopular = data.nome_popular;
            this.form.descricao = data.descricao;
            this.linkItems[1].name = data.nome_tecnico;
            this.getArrayNomesVacinas(data);
        },

        redirectToEdit() {
            this.$router.push(
                { 
                    name: 'doenca-edit', 
                    params: {
                        idDoenca: getIdDoenca()
                    }
                }
            );
        },

        redirecionarLista() {
            this.$router.push({ name: 'doenca-list' });
        },

        openModalError() {
            this.modalError.showModal = true;
        },
        verifyPermissions(action) {
            const permissionDN = this.$can(actions[action], subjects.VACINA_DN)

            return [permissionDN].includes(true);
        }

    }
}
</script>

<style scoped>
.vacinas{
    background-color: #2772C0;
    color: white;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
.altura_max_input_scrollbar{
    max-height:32px;
    word-break: break-all;
    padding-right: 15px;
}
.altura_max_textarea_scrollbar{
    max-height:113px;
    word-break: break-all;
    padding-right: 15px;
}
.altura_max_vacinas_scrollbar{
    max-height:85px;

}
@media (max-width: 767px) { 
    .centralizar_responsivo{
        text-align: center;
        justify-content: center;
    }
}
</style>